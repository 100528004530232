.exchange-container {
  width: 100%;
}
.exchange-logo {
  width: 100%;
  display: grid;
  place-items: center;
  margin: 25px 0;

  p {
    font-family: "Poppins", sans-serif;
    font-size: 1.125rem;
    color: #928b8b;
    font-weight: 300;
  }
}
.exchange-grid {
  display: grid;
  grid-template-columns: 1fr 2px 1fr;

  .exchange-grid-left,
  .exchange-grid-right {
    margin: 0 20px;
  }
}

label {
  display: block;
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  color: #928b8b;
  font-weight: 300;
  margin: 20px 0;
}
select,
input[type="number"] {
  width: 100%;
  border: 1px solid #c7bdbd;

  box-sizing: border-box;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
}
// PLACEHOLDER
.placeholder {
  width: 100%;
  height: 20px;
  margin-bottom: 100px;
}
.button-container-2 {
  margin: 20px 0;
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
}
.btn-add-country {
  background: #6c5ce7;
  color: #fff;
  font-size: 1rem;
  font-weight: 300;
  border: none;
  border-radius: 10px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  width: 85%;
  span {
    margin-left: 10px;
    top: -2px;
    position: relative;
  }
  // margin: 10 auto;
}
// MEDIA QUERY

@media (min-width: 1024px) {
  .exchange-container {
    width: 50%;
    margin: 0 auto;
  }
}