.info-container {
  // background: url("../assets/images/bginfo.svg");
  width: 100%;
}

.info-wrapper {
  margin-top: 50px;
  display: grid;
  place-items: center;
  grid-template-columns: 1fr;

  h1 {
    color: #0a3d62;
    font-weight: 300;
    font-size: 2.25rem;
    font-family: "Poppins", sans-serif;
  }
}
.info-details {
//   margin-top: 50px;
  display: grid;
  place-items: center;
  grid-template-columns: 1fr;

  p {
    color: #908d8d;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
  }
}
.info-logos {
  display: grid;
  place-items: center;
  grid-template-columns: 1fr;
}
// MEDIA QUERY

@media (min-width: 1024px) {
  .info-container {
    width: 50%;
    margin: 0 auto;
  }
}