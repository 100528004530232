.gold-logo {
    display: grid;
    place-items: center;
    width: 100%;
    p {
        font-family: "Poppins", sans-serif;
        font-weight: 300;
        color: #928B8B;
        font-size: 1.125rem;
        span {
            color: #CCC4C4;
        }
    }
}
.gold-item {
    width: 88%;
    margin: 10px auto;
    padding: 1px 15px;
    display: grid;
    grid-template-columns: 60px 1fr 1fr;
    align-items: center;
    // place-items: center;
    border: 1px solid #dad6d6;
    border-radius: 10px;
}
.gold-value {
    h3 {
        font-size: 1.125rem;
        color: #767879;
        font-weight: 500;
        span {
            margin-left: 10px;
            font-weight: 300;
        }
    }
}
.gold-date {
    display: flex;
  flex-direction: column;
  align-items: flex-end;
  h3 {
    font-size: 1.125rem;
    color: #767879;
    font-weight: 400;
  }
}
// PLACEHOLDER
.placeholder {
    width: 100%;
    height: 20px;
    margin-bottom: 100px;
}

// MEDIA QUERY

@media (min-width: 1024px) {
    .gold-container {
      width: 50%;
      margin: 0 auto;
    }
  }