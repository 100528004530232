.container {
  width: 100%;
}
.currency-item {
  width: 88%;
  margin: 10px auto;
  padding: 10px 15px;
  display: grid;
  grid-template-columns: 50px 1fr 100px 15px 20px;
  align-items: center;
 
  border: 1px solid #b9bbbd;
  border-radius: 10px;
  

  
}
.currency-item-sticky {
  width: 88%;
  margin: 10px auto;
  padding: 10px 15px;
  display: grid;
  grid-template-columns: 50px 1fr 100px 15px 20px;
  align-items: center;

  border: 1px solid #b9bbbd;
 
  border-radius: 10px;
  box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.13);
  position: sticky;
  top: 10px;
  background: whitesmoke;
  z-index: 2;
  margin-bottom: 10px;
}

.currency-name {
  h3 {
    font-weight: 600;
    color: #2d3436;
    font-size: 1.125rem;
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
  }
  p {
    color: #aca4a4;
    font-size: 0.875rem;
    font-weight: 300;
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
  }
}
.currency-number {
  
  input[type=number] {
    font-weight: 600;
    color: #656363;
    font-family: "Poppins", sans-serif;
    font-size: 1.125rem;
    width: 100px;
    border: none;
    text-align: right;
    // background: whitesmoke;
    background: none;
    
  }
}

// OTHER COUNTRIES
.currencies-single-item {
  width: 88%;
  margin: 10px auto;
  padding: 10px 15px;
  display: grid;
  grid-template-columns: 50px 1fr 1fr;
  align-items: center;
  // place-items: center;
  border: 1px solid #dad6d6;
  border-radius: 10px;
}
.currencies-name,
.currencies-value {
  h3 {
    font-weight: 600;
    color: #2d3436;
    font-size: 1.125rem;
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
  }
  p {
    color: #aca4a4;
    font-size: 0.875rem;
    font-weight: 300;
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
  }
}
.currencies-value {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  h3 {
    font-weight: 600;
    color: #656363;
    font-family: "Poppins", sans-serif;
    font-size: 1.125rem;
  }
}

// BUTTON
.button-container {
  // margin: 0 auto;
  // border: 1px solid tomato;
  margin: 20px 0;
  display: grid;
  place-items: center;
}
.btn-add-country {
  background: #6c5ce7;
  color: #fff;
  font-size: 1rem;
  font-weight: 300;
  border: none;
  border-radius: 10px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  width: 85%;
  span {
      margin-left: 10px;
      top: -2px;
      position: relative;
  }
  // margin: 10 auto;
}
.btn-calc {
  border: none;
  background: none;
  margin-left: -5px
}
// MORE CURRENCIES
.more-currencies {
  background: #fff;
  width: 100%;
  height: 100vh;
  transform: translateX(0%);
  position: fixed;
  top: 0;
  z-index: 5;
  transition: transform .2s ease-in;
  // border: 1px solid tomato;
}
.btn-add-country-opened {
  background: #6c5ce7;
  color: #fff;
  font-size: 1rem;
  font-weight: 300;
  border: none;
  // border-radius: 10px;
  padding: 20px;
  font-family: "Poppins", sans-serif;
  width: 100%;
  span {
      margin-left: 10px;
      top: -2px;
      position: relative;
  }
  // margin: 10 auto;
}
.more-item {
  display: grid;
  grid-template-columns: 40px 1fr 20px;
  align-items: center;
  // padding: 10px 5px;
  border-bottom: 1px solid #E3E1E1;
  width: 85%;
  margin: 0 auto;
  &:hover {
    cursor: pointer;
    border-bottom: 1px solid #6c5ce7
  }
  p {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #2d3436;
    font-size: 1rem;
    span {
      color: #ACA4A4;
      font-weight: 300;
      font-size: 0.825rem;
    }
  }
}
// PLACEHOLDER
.placeholder {
    width: 100%;
    height: 20px;
    margin-bottom: 100px;
}


// MEDIA QUERY

@media (min-width: 1024px) {
  .container,
  .more-currencies {
    width: 50%;
    margin: 0 auto;
  }
}