header {
    
    height: 75px;
    border-bottom: 1px solid #E6E9EC;
    div {
    display: grid;
    place-items: center;
    grid-template-columns: 48px 1fr;
    width: 60%;
    margin: 5px auto;
    // border: 1px solid tomato;
    }
    h1 {
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        color: #0A3D62;
        font-size: 1.5rem;
    }
}

@media (min-width: 1024px) {
    header {
        width: 35%;
        margin: 0 auto;
    }
}