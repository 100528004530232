body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
nav {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-top: 1px solid #E6E9EC;
  background: #fff;
  z-index: 1;
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 100px;
  gap: 5px;
  place-items: center;
  .menu-item {
    display: grid;
    place-items: center;
    // border: 1px solid tomato;
  }
  p {
    color: #8E9294;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
  }

  a {
    text-decoration: none;
  }
  
}
// MEDIA QUERY

@media (min-width: 1024px) {
  .main-component {
    width: 100%;
    background: pink;
  }
  footer {
    display: grid;
    place-items: center;
    width: 55%;
    margin: 0 auto;
  }
  nav {
    width: 55%;
    margin: 0 auto;
  }
}

.aktive {
  background: pink;
  border: 1px solid tomato;
}